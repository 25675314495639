export const APPLICATION_NAME = 'PEER-TO-PEER PROGRAM DESIGNER';
export const APPLICATION_NAME_SMALL = 'P2P PROGRAM DESIGNER';
export const APPLICATION_TITLE = 'P2P PROGRAM DESIGN';
export const PROGRAM_LANDSCAPE = 'Program Landscape';
export const SPEAKER_BUREAU_HEALTH = 'Speaker Bureau Health';
export const NO_SEARCH_RESULTS = 'Your search did not yield any results. Please refine the keywords and try again.';
export const NO_DATA_FOUND = 'No Data Found';
export const SOMETHING_WENT_WRONG = 'Something went wrong! Please try again later.';
export const CREATE_ADD_ATTENDEE = 'create';
export const EVENT_ADD_ATTENDEE = 'event';


export const TOASTER_TIMEOUT = 4000;

export const API_SUCCESS_KEY = 'SUCCESS';
export const API_FAILURE_KEY = 'FAILED';

export const APP_STATE_KEYS = {
  USER_DETAILS: 'userDetails'
};