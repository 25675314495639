import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { APPLICATION_NAME } from './shared/constants/app-constants';
import { CustomIconService } from './shared/services/custom-icon.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private titleService: Title,
    private customIconService: CustomIconService) {
  }

  ngOnInit() {
    this.titleService.setTitle(APPLICATION_NAME);
    this.customIconService.addCustomIconsToNzLibrary();
  }
}
