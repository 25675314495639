import { Injectable } from '@angular/core';
import {
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpUserEvent
} from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {

  addCommonHttpOptions(request: HttpRequest<any>): HttpRequest<any> {
    return request.clone({
      withCredentials: true
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    const customRequest = this.addCommonHttpOptions(req);
    return next.handle(customRequest).pipe(
      catchError((err) => {
        if (err.status === 401) {
          if (environment.production) {
            const url = `${environment.loginUrl}?ReturnUrl=${encodeURIComponent(window.location.origin)}`;
            window.open(url, '_self');
          }
          return EMPTY;
        } else {
          return throwError(err);
        }
      })
    );
  }
}
